import React, { useEffect, useState, useRef, useMemo } from "react";
import { Card, CardBody, Col, Container, Row, Table, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import LabelWithTooltip from "components/common/LabelWithTooltip";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { consentCertificateDetailsRequest } from "store/consentCertificates/actions";
import ReplayerComponent from "components/ReplayerComponent/ReplayerComponent";
import consentBadge from '../../assets/images/consent-badge.png';
import { getIANATimezoneStringForAbbreviation, timeFormatter } from "helpers/utlis";
import ErrorPage from "components/ErrorPage/ErrorPage";
import { replayRequest } from "store/replay/actions";
import i18n_keys from "i18n_keys";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "components/common/LoadingSpinner";
import _ from "lodash";
import { REPORTING_TIMEZONE } from "helpers/constants";
import './ConsentCertificateDetails.css';
import CustomSkeleton from "components/common/CustomSkeleton";
import { formCompletedFormatter, insuranceCarrierListFormatter } from "utils/Formatter";
import TcpaDisclaimerModal from "./Modal/TcpaDisclaimerModal";

const ConsentCertificateDetails = () => {
  const { certificateId } = useParams();
  const dispatch = useDispatch();
  const { consent, eventsToShow, loading, error } = useSelector((state) => ({
    consent: state.consentCertificateReducer.data,
    eventsToShow: state.replayReducer.data,
    loading: state.replayReducer.loading,
    error: state.replayReducer.error,
  }));
  const { t } = useTranslation();
  const hasEventsToShow = eventsToShow && eventsToShow.length > 0;
  const cardBodyRef = useRef(null);
  const [viewTcpaModal, setViewTcpaModal] = useState({ action: false })

  useEffect(() => {
    if (certificateId) {
      dispatch(replayRequest(certificateId));
      dispatch(consentCertificateDetailsRequest(certificateId));
    }
  }, [certificateId, dispatch]);

  const { widthInPixels, heightInPixels } = useMemo(() => {
    let width = 0;
    let height = 0;
    if (cardBodyRef.current) {
      width = cardBodyRef.current.clientWidth - 50;
      height = cardBodyRef.current.clientHeight - 100;
    }
    return { widthInPixels: width, heightInPixels: height };
  }, [consent, cardBodyRef.current]);

  const eventsToShowCopy = useMemo(() => {
    return _.cloneDeep(eventsToShow);
  }, [eventsToShow])

  return (
    <div className="float-container">
      <div className="float-deatils" md="w-auto">
        <Card className="cosent-card">
          <CardBody className="py-0">
            <Row>
              <div className="px-0">
                <div className="table-responsive px-0">
                  {loading ?
                    <CustomSkeleton rows={10} cols={1} /> :
                    <Table className="mb-0 text-black">
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <div className="d-flex align-items-center mb-2">
                                <img src={consentBadge} alt='' />
                              </div>
                              <p className="mb-0">Certificate of Authenticity issued <br /> by Consent Confirmation
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Certificate ID'
                                tooltip_message={t(i18n_keys.CERTIFICATE_ID_TOOLTIP_MESSAGE)}
                                target='certificate-id-tooltip'
                                is_active={true}
                              />
                              <div className="d-flex align-items-top mb-0">{consent?.certificate_id}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Date'
                                tooltip_message={t(i18n_keys.DATE_TOOLTIP_MESSAGE)}
                                target='date-tooltip'
                                is_active={true}
                              />
                              <p className="mb-0">{consent?.date && format(utcToZonedTime(new Date(consent?.date), getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)), 'MM/dd/yyyy hh:mm aa')}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Form Completed'
                                tooltip_message={t(i18n_keys.FORM_COMPLETED_TOOLTIP_MESSAGE)}
                                target='consent-form-completed-tooltip'
                                is_active={true}
                              />
                              {formCompletedFormatter(consent?.is_completed, true)}
                            </div>
                          </td>
                        </tr>
                        {(consent?.is_completed && consent?.insurance_carriers?.length > 0) && (
                          <>
                            <tr>
                              <td style={{ cursor: 'default' }}>
                                <div>
                                  <LabelWithTooltip
                                    name="Consent Captured"
                                    tooltip_message={t(i18n_keys.CONSENT_CAPTURED_TOOLTIP_MESSAGE)}
                                    target="consent-captured-tooltip"
                                    is_active={true}
                                  />
                                  {formCompletedFormatter(consent?.is_completed, true)}
                                  {insuranceCarrierListFormatter(consent?.insurance_carriers || [])}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ cursor: 'default' }}>
                                <div>
                                  <LabelWithTooltip
                                    name="TCPA Disclaimer"
                                    tooltip_message={t(i18n_keys.TCPA_DISCLAIMER_TOOLTIP_MESSAGE)}
                                    target="tcpa-disclaimer-tooltip"
                                    is_active={true}
                                  />

                                  {consent?.tcpa_disclaimer_text ? (
                                    <>
                                      <a
                                        href="#"
                                        target="_blank"
                                        className="mb-0"
                                        style={{ color: '#2f9ee9', textDecoration: 'none' }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setViewTcpaModal({ action: true });
                                        }}
                                      >
                                        View Disclaimer
                                      </a>
                                      <TcpaDisclaimerModal
                                        state={viewTcpaModal}
                                        disclaimerText={
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: consent.tcpa_disclaimer_text,
                                            }}
                                          />
                                        }
                                        onCloseStateEvent={() => setViewTcpaModal({ action: false })}
                                      />
                                    </>
                                  ) : (
                                    <div>No TCPA Disclaimer found</div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Duration'
                                tooltip_message={t(i18n_keys.DURATION_TOOLTIP_MESSAGE)}
                                target='consent-duration-tooltip'
                                is_active={true}
                              />
                              <p className='mb-0'>{consent?.duration ? timeFormatter(consent?.duration) : 'In Progress'}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <LabelWithTooltip
                                name='Page URL'
                                tooltip_message={t(i18n_keys.PAGE_URL_TOOLTIP_MESSAGE)}
                                target='consent-page-url-tooltip'
                                is_active={true}
                              />
                              <p className='text-truncate mb-0' style={{
                                width: "221px",
                                overflow: "hidden",
                                position: 'relative',
                              }}>
                                <a id='certificate-page-url' href={consent?.source_page_url} target='_blank' className='mb-0 ' style={{ color: '#2f9ee9', textDecoration: 'none' }}>{consent?.source_page_url}</a>
                              </p>
                              <UncontrolledTooltip placement="top-start" target='certificate-page-url'>{consent?.source_page_url}</UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='IP Address'
                                tooltip_message={t(i18n_keys.IP_ADDRESS_TOOLTIP_MESSAGE)}
                                target='consent-ip-address-tooltip'
                                is_active={true}
                              />
                              <p className='mb-0'>{consent?.ip}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Location'
                                tooltip_message={t(i18n_keys.LOCATION_TOOLTIP_MESSAGE)}
                                target='consent-location-tooltip'
                                is_active={true}
                              />
                              <p className='mb-0'>{consent?.location}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ cursor: 'default' }}>
                            <div>
                              <LabelWithTooltip
                                name='Browser & OS'
                                tooltip_message={t(i18n_keys.BROWSER_TOOLTIP_MESSAGE)}
                                target='consent-browser-os-tooltip'
                                is_active={true}
                              />
                              <p className='mb-0'>{consent?.os_browser_details}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>}
                </div>

              </div>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="float-consent">
        <Card >
          <CardBody>
            <div className='card-body-wrapper' ref={cardBodyRef}>
              {loading ? (
                <LoadingSpinner />
              ) : error ? (
                <ErrorPage errorMsg={t(i18n_keys.ERROR_FETCHING_DATA)} />
              ) : hasEventsToShow ? (
                <ReplayerComponent events={eventsToShowCopy} width={widthInPixels} height={heightInPixels} />
              ) : (
                <ErrorPage errorMsg={t(i18n_keys.NO_EVENTS_TO_DISPLAY)} />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
};

export default ConsentCertificateDetails;
