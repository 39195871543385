// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { MetaTags } from 'react-meta-tags';
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { META_TYPES, REPORTING_TIMEZONE } from "helpers/constants"
import DateRangePicker, { DATE_RANGE_OPTIONS, DATE_RANGE_OPTIONS_DISPLAY, createOption } from '../../components/common/DateRangePicker';
import Breadcrumbs from "../../components/common/Breadcrumb";
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes';
import { getIANATimezoneStringForAbbreviation } from "helpers/utlis";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardBrowserTypeStats, fetchDashboardCompareToMainStats, fetchDashboardDeviceTypeStats, fetchDashboardDomainStats, fetchDashboardMainDetailStats, fetchDashboardMainStats, fetchDashboardStateStats } from "store/dashboard/actions";
import _ from "lodash";
import GraphStatsWidgets from "./GraphStatsWidgets";
import DashboardStateMap from "./DashboardStateMap";
import Skeleton from "react-loading-skeleton";
import { fetchCampaignsRequest } from "store/actions";
import { changePrevTimeRangeBySelectedTimeRange, getPrevDataRangeFromSelectedDataRange } from "helpers/campaign_helper";
import { t } from "i18next";
import i18n_keys from "i18n_keys";

function Dashboard() {
  document.title = `Dashboard ${t(i18n_keys.PAGE_TITLE_SUFFIX)}`;
  const queryParams = {};

  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState(createOption(
    DATE_RANGE_OPTIONS.TODAY,
    new Date(),
    getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
  ));

  const [selectedCampaign, setSelectedCampaign] = useState([{
        "label": "All Campaigns",
        "value": "all"
    }]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [compare, setCompare] = useState(false);
  const [showDiff, setShowDiff] = useState(false);
  const [prevselectedDateRange, setPrevSelectedDateRange] = useState(createOption(
    DATE_RANGE_OPTIONS.LAST_MONTH,
    new Date(),
    getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
  ));
  const [lastDateLabel, setLastDateLabel] = useState(prevselectedDateRange.label);
  const [viewMode, setViewMode] = useState(null);
  const {
    main_stats,
    main_stats_loading,
    compare_to_main_stats,
    compare_to_main_stats_loading,
    main_detail_stats,
    main_detail_stats_loading,
    device_type_stats,
    device_type_stats_loading,
    browser_type_stats,
    browser_type_stats_loading,
    domain_stats,
    domain_stats_loading,
    state_stats,
    state_stats_loading,
    campaigns
  } = useSelector((state) => ({
    main_stats: state.Dashboard.main_stats,
    main_stats_loading: state.Dashboard.main_stats_loading,
    compare_to_main_stats: state.Dashboard.compare_to_main_stats,
    compare_to_main_stats_loading: state.Dashboard.compare_to_main_stats_loading,
    main_detail_stats: state.Dashboard.main_detail_stats,
    main_detail_stats_loading: state.Dashboard.main_detail_stats_loading,
    device_type_stats: state.Dashboard.device_type_stats,
    device_type_stats_loading: state.Dashboard.device_type_stats_loading,
    browser_type_stats: state.Dashboard.browser_type_stats,
    browser_type_stats_loading: state.Dashboard.browser_type_stats_loading,
    domain_stats: state.Dashboard.domain_stats,
    domain_stats_loading: state.Dashboard.domain_stats_loading,
    state_stats: state.Dashboard.state_stats,
    state_stats_loading: state.Dashboard.state_stats_loading,
    campaigns: state.campaignReducer.campaignList,
  }));
  const onClickUpdate = () => {
    setViewMode(null);
    const dateRangeValue = _.cloneDeep(selectedDateRange?.value);
    let clonePrevSelectedDateRange = _.cloneDeep(prevselectedDateRange)
    let prevSelectedStartDate = clonePrevSelectedDateRange.value.startDate
    let prevSelectedEndDate = clonePrevSelectedDateRange.value.endDate
    let cloneSelctedDateRange = _.cloneDeep(selectedDateRange)
    prevSelectedEndDate = changePrevTimeRangeBySelectedTimeRange(clonePrevSelectedDateRange, cloneSelctedDateRange)
    if (dateRangeValue?.startDate) {
      queryParams.start_date = dateRangeValue.startDate.toISOString();
    }
    if (dateRangeValue?.endDate) {
      queryParams.end_date = dateRangeValue.endDate.toISOString();
    }
    if (selectedCampaign?.length) {
      queryParams.campaign_ids = (selectedCampaign || []).map((o) => o.value).filter(e => e !== 'all');
    }
    dispatch(fetchDashboardMainStats(queryParams));
    dispatch(fetchDashboardMainDetailStats({
      ...queryParams,
      compare_to_start_date: compare && prevSelectedStartDate ? prevSelectedStartDate.toISOString() : null,
      compare_to_end_date: compare && prevSelectedEndDate ? prevSelectedEndDate.toISOString() : null
    }));
    dispatch(fetchDashboardDeviceTypeStats(queryParams));
    dispatch(fetchDashboardBrowserTypeStats(queryParams));
    dispatch(fetchDashboardDomainStats(queryParams));
    dispatch(fetchDashboardStateStats(queryParams));
    if (compare && prevSelectedStartDate && prevSelectedEndDate) {
      const params = {}
      params.start_date = prevSelectedStartDate.toISOString();
      params.end_date = prevSelectedEndDate.toISOString();
      params.campaign_ids = (selectedCampaign || []).map((o) => o.value).filter(e => e !== 'all');
      dispatch(fetchDashboardCompareToMainStats(params));
    }
  }

  useEffect(() => {
    if ((main_detail_stats.find((e) => e.meta === META_TYPES.PREV))) {
      setShowDiff(true);
    } else {
      setShowDiff(false);
    }
    switch (prevselectedDateRange.label) {
      case DATE_RANGE_OPTIONS_DISPLAY.YESTERDAY:
      case DATE_RANGE_OPTIONS_DISPLAY.LAST_WEEK:
      case DATE_RANGE_OPTIONS_DISPLAY.LAST_MONTH:
        setLastDateLabel(prevselectedDateRange?.label);
        break;
      default:
        setLastDateLabel('Last Period');
        break;
    }
  }, [main_detail_stats]);

  useEffect(() => {
    const prev_date_range_options = getPrevDataRangeFromSelectedDataRange(
      selectedDateRange,
      getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
    );
    setPrevSelectedDateRange(prev_date_range_options[0]);
  }, [selectedDateRange]);

  useEffect(() => {
    onClickUpdate();
    dispatch(fetchCampaignsRequest());
  }, []);

  useEffect(() => {
    if (campaignOptions?.length > 0) {
      setSelectedCampaign(campaignOptions);
    }
  }, [campaignOptions]);

  useEffect(() => {
    const campaign_options = (campaigns || []).map((campaign) => ({ label: campaign.name, value: campaign.id }));
    const campaignOpts = [];
    campaignOpts.push({ label: 'All Campaigns', value: 'all' });
    setSelectedCampaign(campaignOpts);
    const mappedCampaignOpts = campaignOpts.concat(campaign_options);
    setCampaignOptions(mappedCampaignOpts);
  }, [campaigns]);

  function getDropdownButtonLabelForCampaign({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === 'all')) {
      return <span className='css-1v99tuv text-black align-self-center'>All Campaigns</span>;
    }
    return <span className='css-1v99tuv text-black align-self-center'>{value.length} selected</span>;
  }

  function handleSelectedCampaign(selectedOption, event) {
    if (event.action === 'select-option' && event.option.value === 'all') {
      setSelectedCampaign(campaignOptions);
    } else if (
      event.action === 'deselect-option'
      && event.option.value === 'all'
    ) {
      setSelectedCampaign([]);
    } else if (event.action === 'deselect-option') {
      setSelectedCampaign(selectedOption.filter((o) => o.value !== 'all'));
    } else if (selectedOption.length === campaignOptions.length - 1) {
      setSelectedCampaign(campaignOptions);
    } else {
      setSelectedCampaign(selectedOption);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Dashboard" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className='d-flex flex-row'>
                    <Col className="align-self-end col-md-3 col-3">
                      <FormGroup className='mb-0'>
                        <Label className='font-weight-semibold'>Campaign</Label>
                        <ReactMultiselectCheckboxes
                          className="select2-selection__control css-13cymwt-control custom-multi-select-checkbox"
                          hideSearch={true}
                          getDropdownButtonLabel={getDropdownButtonLabelForCampaign}
                          value={selectedCampaign}
                          onChange={(selectedOption, event) => {
                            handleSelectedCampaign(selectedOption, event);
                          }}
                          options={campaignOptions}
                        />
                      </FormGroup>
                    </Col>
                    <Col md='3' className="align-self-end">
                      <FormGroup className="mb-0">
                        <Label className="visually-hidden">Date :</Label>
                        <DateRangePicker
                          ianaTz={getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)}
                          value={selectedDateRange}
                          options={Object.keys(DATE_RANGE_OPTIONS).map((opt) => createOption(
                            DATE_RANGE_OPTIONS[opt],
                            new Date(),
                            getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
                          ))}
                          onChange={(value) => {
                            setSelectedDateRange(value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="auto" className="align-self-end">
                      <div>
                        <FormGroup className="mb-0 sf-checkbox">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="compare"
                            checked={compare}
                            onChange={(event) => {
                              setCompare(event.target.checked);
                            }}
                            onClick={() => {
                              setCompare(!compare);
                            }}
                          />
                          <label className="text-black" htmlFor="compare" style={{ cursor: 'pointer' }}>Compared to</label>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col className="align-self-end">
                      <div>
                        <FormGroup className="mb-0">
                          <Label className="visually-hidden">Date :</Label>
                          <DateRangePicker
                            ianaTz={getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)}
                            value={prevselectedDateRange}
                            options={Object.keys(DATE_RANGE_OPTIONS).map((opt) => createOption(
                              DATE_RANGE_OPTIONS[opt],
                              new Date(),
                              getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
                            ))}
                            onChange={(value) => {
                              setPrevSelectedDateRange(value);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col className="align-self-end">
                      <div>
                        <FormGroup className="mb-0">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={onClickUpdate}>
                            {(main_stats_loading || main_detail_stats_loading || device_type_stats_loading || browser_type_stats_loading || domain_stats_loading || state_stats_loading) ? "Loading Data..." : 'Update'}
                          </button>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {(main_stats_loading || main_detail_stats_loading || device_type_stats_loading || browser_type_stats_loading || domain_stats_loading || state_stats_loading) ?
              [1, 2, 3, 4, 5, 6].map((item) => (
                <Col md={4} key={item}>
                  <Card className="sfgraph-widget-card" style={{ height: '160px' }}>
                    <CardBody className="p-3 pb-2">
                      <div className="mt-2 mb-3">
                        <Skeleton />
                      </div>
                      <Skeleton count={3} />
                    </CardBody>
                  </Card>
                </Col>
              )) :
              (
                <Col md={12}>
                  <GraphStatsWidgets
                    viewMode={viewMode}
                    updateViewMode={setViewMode}
                    main_stats={main_stats}
                    main_stats_loading={main_stats_loading}
                    compare_to_main_stats={compare_to_main_stats}
                    compare_to_main_stats_loading={compare_to_main_stats_loading}
                    main_detail_stats={main_detail_stats}
                    main_detail_stats_loading={main_detail_stats_loading}
                    device_type_stats={device_type_stats}
                    device_type_stats_loading={device_type_stats_loading}
                    browser_type_stats={browser_type_stats}
                    browser_type_stats_loading={browser_type_stats_loading}
                    domain_stats={domain_stats}
                    domain_stats_loading={domain_stats_loading}
                    state_stats={state_stats}
                    state_stats_loading={state_stats_loading}
                    showDiff={showDiff}
                    lastDateLabel={lastDateLabel}
                    compare={compare}
                  />
                </Col>
              )
            }

            <Col className="col-12">
              <DashboardStateMap
                stateData={state_stats}
                stateDataLoading={state_stats_loading}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
