import { APEX_GRAPH_MINI_OPTIONS, APEX_GRAPH_DETAILED_OPTIONS, DASHBOARD_METRIC_NAMES, META_TYPES, METRIC_TYPES, METRIC_TYPE_NAMES, VIEW_MODE_TYPE, MULTIPLE_GRAPH_COLORS, COMPARED_MULTIPLE_GRAPH_COLORS } from 'helpers/constants';
import React, { useState, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import GraphWidget from './GraphWidgets';
import StatWidgets from './StatWidgets';
import { filterDataByPropertyValue, parseTimeframeStart, percIncrease, xAxisOffsetForHourTimeFrame } from 'helpers/utlis';
import { timeDurationFormatter } from 'utils/Formatter';
import i18n_keys from "i18n_keys";
import { t } from "i18next"

const mapAndSortStats = (statsArray, labelKey, valueKey, isPercentage = false) => {
    return [...(statsArray || [])].sort((a, b) => b[valueKey] - a[valueKey]).map(el => ({
        label: el[labelKey],
        value: isPercentage ? `${parseFloat(el[valueKey])}%` : el[valueKey]
    }));
};
const device_type_colors = ["#07306B", "#1B5793", "#307EBC", "#4E96C8", "#6DAED5", "#91C1DF", "#B5D4E9", "#D6E7F4", "#F7FBFF"];
const browser_type_colors = ["#023721", "#145439", "#277252", "#398f6b", "#4cad84", "#4cad84", "#6cba99", "#8dc7af", "#aed5c4", "#cfe2da", "#f0f0f0"];

const GraphStatsWidgets = ({
    main_stats,
    main_stats_loading,
    main_detail_stats,
    main_detail_stats_loading,
    device_type_stats,
    device_type_stats_loading,
    browser_type_stats,
    browser_type_stats_loading,
    domain_stats,
    domain_stats_loading,
    state_stats,
    state_stats_loading,
    viewMode,
    updateViewMode,
    showDiff,
    compare_to_main_stats,
    compare_to_main_stats_loading,
    lastDateLabel,
    compare,
}) => {
    const [detailed, setDetailed] = useState(false)

    const calculateMetricDiff = (label) => {
        const from_data = compare_to_main_stats;
        const to_data = main_stats;
        if (!from_data || !to_data) {
            return null
        }
        switch (label) {
            case METRIC_TYPES.SESSIONS:
                return percIncrease(
                    from_data?.total_certificates,
                    to_data?.total_certificates
                )
            case METRIC_TYPES.DURATION:
                return percIncrease(
                    from_data?.average_duration,
                    to_data?.average_duration
                )
            default:
                break;
        }
    };
    const graph_main_data = useMemo(() => {
        if (!main_stats && !main_detail_stats) {
            return [];
        }
        return [METRIC_TYPES.SESSIONS, METRIC_TYPES.DURATION].map((metric) => {
            const dataDiff = calculateMetricDiff(metric)
            const compare_to_data = (showDiff && !compare_to_main_stats_loading && compare_to_main_stats ? compare_to_main_stats[metric] : null)
            const compare_to_form_completed = (showDiff && !compare_to_main_stats_loading && compare_to_main_stats ? compare_to_main_stats['total_completed'] : null)
            const series = [META_TYPES.CURRENT, META_TYPES.PREV].map((meta) => {
                const meta_data = filterDataByPropertyValue(main_detail_stats, 'meta', meta)
                return {
                    name: meta,
                    data: meta_data.map(item => parseInt(item[metric]))
                }
            })
            let combinedSeries = series;

            if (METRIC_TYPES.SESSIONS === metric) {
                const sessionSeries = [META_TYPES.CURRENT, META_TYPES.PREV].map((meta) => {
                    const meta_data = filterDataByPropertyValue(main_detail_stats, 'meta', meta);
                    return {
                        name: meta === META_TYPES.CURRENT ? META_TYPES.SUCCESS_CURRENT : META_TYPES.SUCCESS_PREV,
                        data: meta_data.map(item => parseInt(item['total_completed']))
                    };
                });
                combinedSeries = [...series, ...sessionSeries];
            }
            return {
                current_data: main_stats[metric],
                last_data: (metric === METRIC_TYPES.DURATION ? timeDurationFormatter(compare_to_data) : new Intl.NumberFormat().format(compare_to_data)),
                label: METRIC_TYPE_NAMES[metric],
                lastDateLabel: lastDateLabel,
                series: combinedSeries,
                dataDiff: dataDiff ? `${dataDiff.toString()}%` : null,
                compare: compare,
                currentCompletedForms: main_stats['total_completed'],
                lastCompletedForms: (metric === METRIC_TYPES.DURATION ? timeDurationFormatter(compare_to_data) : new Intl.NumberFormat().format(compare_to_form_completed)),
            };
        })
    }, [main_stats, main_detail_stats, compare_to_main_stats, showDiff, compare, lastDateLabel]);

    const { mappdedDeviceTypeStats, mappdedDeviceTypeGraphStats } = useMemo(() => {
        const mappded_device_type_stats = mapAndSortStats(device_type_stats, 'device_type', 'percentage', true);
        const mappded_device_type_graph_labels = mappded_device_type_stats.map(el => el.label);
        const mappded_device_type_graph_values = mappded_device_type_stats.map(el => parseFloat(el.value) || 0);

        return {
            mappdedDeviceTypeStats: mappded_device_type_stats,
            mappdedDeviceTypeGraphStats: {
                labels: mappded_device_type_graph_labels,
                values: mappded_device_type_graph_values
            }
        };
    }, [device_type_stats]);

    const { mappdedBrowserTypeStats, mappdedBrowserTypeGraphStats } = useMemo(() => {
        const mappded_browser_type_stats = mapAndSortStats(browser_type_stats, 'browser_type', 'percentage', true);
        const mappded_browser_type_graph_labels = mappded_browser_type_stats.map(el => el.label);
        const mappded_browser_type_graph_values = mappded_browser_type_stats.map(el => parseFloat(el.value) || 0);

        return {
            mappdedBrowserTypeStats: mappded_browser_type_stats,
            mappdedBrowserTypeGraphStats: {
                labels: mappded_browser_type_graph_labels,
                values: mappded_browser_type_graph_values
            }
        };
    }, [browser_type_stats]);

    const mappdedDomainStats = useMemo(() => {
        return [...(domain_stats || [])].sort((a, b) => b.total_certificates - a.total_certificates).map(el => ({ "label": el.domain, "value": el.total_certificates }))
    }, [domain_stats]);

    const mappdedStateStats = useMemo(() => {
        return [...(state_stats || [])].sort((a, b) => b.total_certificates - a.total_certificates).map(el => ({ "label": (`${el.state} (${el.state_code})`), "value": el.total_certificates }))
    }, [state_stats]);

    const onClickChangeMode = (items, stats = main_detail_stats) => {
        const parsedCategories = parseTimeframeStart(stats);
        const mappdedCategories = parsedCategories.map(item => item.meta);
        const xaxis = xAxisOffsetForHourTimeFrame(parsedCategories[0])
        const options = {
            ...APEX_GRAPH_DETAILED_OPTIONS,
            xaxis: {
                ...xaxis,
                categories: mappdedCategories,
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return items?.label === METRIC_TYPE_NAMES.average_duration ? timeDurationFormatter(value) : new Intl.NumberFormat().format(value);
                    }
                },
            },
        }
        if (items.label === METRIC_TYPE_NAMES.total_certificates) {
            options.colors = items.compare ? COMPARED_MULTIPLE_GRAPH_COLORS : MULTIPLE_GRAPH_COLORS;
        }
        const data = {
            ...items,
            options: options
        }
        updateViewMode(data);
    }

    const closeWidget = () => {
        setDetailed(false)
        updateViewMode(null);
    }

    const GraphOptions = (item) => {
        let options = { ...APEX_GRAPH_MINI_OPTIONS };
        if (item.label === METRIC_TYPE_NAMES.total_certificates) {
            options.colors = item.compare ? COMPARED_MULTIPLE_GRAPH_COLORS : MULTIPLE_GRAPH_COLORS;
        }
        return options;
    };

    const tooltipData = (type) => {
        const id = type === 'Sessions' ? 'sessions' : 'avg-duration';
        const text = type === 'Sessions' ? t(i18n_keys.SESSIONS_TOOLTIP_MESSAGE) : t(i18n_keys.AVG_DURATION_TOOLTIP_MESSAGE);
        return { id, text };
    };

    return (
        <Row>
            {detailed ? (
                <Col md={12}>
                    <GraphWidget
                        closeWidget={closeWidget}
                        graphType={VIEW_MODE_TYPE.DETAILED}
                        graphOption={viewMode?.options || []}
                        filterType={viewMode?.label}
                        loading={main_detail_stats_loading || main_stats_loading}
                        currentData={viewMode?.current_data}
                        series={viewMode?.series}
                        isTooltip={true}
                        tooltip={tooltipData(viewMode?.label)}
                        height={300}
                        showDiff={showDiff}
                        lastDateLabel={viewMode?.lastDateLabel}
                        lastData={viewMode?.last_data}
                        currentCompletedForms={viewMode.currentCompletedForms}
                        lastCompletedForms={viewMode.lastCompletedForms}
                        dataDiff={viewMode?.dataDiff}
                        compare={viewMode?.compare}
                    />
                </Col>
            )
                : (
                    graph_main_data || []).map((item, index) => {
                        return (
                            <Col md={4} key={index}
                                onClick={() => { setDetailed(true); onClickChangeMode(item) }}
                                className="custom-pointer">
                                <GraphWidget
                                    graphType={VIEW_MODE_TYPE.MINI}
                                    graphOption={GraphOptions(item)}
                                    loading={main_stats_loading || main_detail_stats_loading}
                                    currentData={item.current_data}
                                    lastData={item?.last_data}
                                    currentCompletedForms={item.currentCompletedForms}
                                    lastCompletedForms={item.lastCompletedForms}
                                    series={item.series}
                                    filterType={item.label}
                                    isTooltip={true}
                                    tooltip={tooltipData(item.label)}
                                    height={90}
                                    showDiff={showDiff}
                                    lastDateLabel={lastDateLabel}
                                    dataDiff={item?.dataDiff}
                                    compare={item?.compare}
                                />
                            </Col>
                        )
                    })
            }
            <Col md={viewMode?.current_data ? 6 : 4} key={DASHBOARD_METRIC_NAMES.DEVICE_TYPE}>
                <StatWidgets
                    graphMode={VIEW_MODE_TYPE.MINI}
                    graphOption={APEX_GRAPH_MINI_OPTIONS}
                    colors={device_type_colors}
                    loading={device_type_stats_loading}
                    currentData={mappdedDeviceTypeStats}
                    filterType={DASHBOARD_METRIC_NAMES.DEVICE_TYPE}
                    isPieChart={true}
                    pieChartData={mappdedDeviceTypeGraphStats}
                    isTooltip={true}
                    tooltip={{ id: 'device-types', text: t(i18n_keys.DEVICE_TYPE_TOOLTIP_MESSAGE) }}
                    height={90}
                />
            </Col>
            <Col md={viewMode?.current_data ? 6 : 4} key={DASHBOARD_METRIC_NAMES.BROWSER_TYPE}>
                <StatWidgets
                    graphMode={VIEW_MODE_TYPE.MINI}
                    graphOption={APEX_GRAPH_MINI_OPTIONS}
                    colors={browser_type_colors}
                    loading={browser_type_stats_loading}
                    currentData={mappdedBrowserTypeStats}
                    filterType={DASHBOARD_METRIC_NAMES.BROWSER_TYPE}
                    isPieChart={true}
                    pieChartData={mappdedBrowserTypeGraphStats}
                    isTooltip={true}
                    tooltip={{ id: 'browser-types', text: t(i18n_keys.TOP_BROWSERS_TOOLTIP_MESSAGE) }}
                    height={90}
                />
            </Col>
            <Col md={viewMode?.current_data ? 6 : 4} key={DASHBOARD_METRIC_NAMES.TOP_DOMAINS}>
                <StatWidgets
                    graphMode={VIEW_MODE_TYPE.MINI}
                    graphOption={APEX_GRAPH_MINI_OPTIONS}
                    loading={domain_stats_loading}
                    currentData={mappdedDomainStats}
                    filterType={DASHBOARD_METRIC_NAMES.TOP_DOMAINS}
                    isPieChart={false}
                    isTooltip={true}
                    tooltip={{ id: 'to-domains', text: t(i18n_keys.TOP_DOMAINS_TOOLTIP_MESSAGE) }}
                    height={90}
                />
            </Col>
            <Col md={viewMode?.current_data ? 6 : 4} key={DASHBOARD_METRIC_NAMES.TOP_STATES}>
                <StatWidgets
                    graphMode={VIEW_MODE_TYPE.MINI}
                    graphOption={APEX_GRAPH_MINI_OPTIONS}
                    loading={state_stats_loading}
                    currentData={mappdedStateStats}
                    filterType={DASHBOARD_METRIC_NAMES.TOP_STATES}
                    isPieChart={false}
                    isTooltip={true}
                    tooltip={{ id: 'top-states', text: t(i18n_keys.TOP_STATES_TOOLTIP_MESSAGE) }}
                    height={90}
                />
            </Col>
        </Row>
    )
}

export default GraphStatsWidgets;
