import * as action from './actionTypes';

export const fetchCampaignEvents = (campaignData) => ({
  type: action.GET_CAMPAIGN_EVENTS,
  payload: campaignData,
});

export const fetchCampaignEventsSuccess = (payload) => ({
  type: action.GET_CAMPAIGN_EVENTS_SUCCESS,
  payload: payload
});

export const fetchCampaignEventsError = (error) => ({
  type: action.GET_CAMPAIGN_EVENTS_ERROR,
  payload: error,
});

export const fetchFilterdCampaignEvents = (campaignData) => ({
  type: action.GET_FILTERED_CAMPAIGN_EVENTS,
  payload: campaignData,
})

export const fetchClientListFromGoal = () => ({
  type: action.GET_CLIENT_LIST_FROM_GOAL,
  payload: {},
});

export const fetchClientListFromGoalSuccess = (payload) => ({
  type: action.GET_CLIENT_LIST_FROM_GOAL_SUCCESS,
  payload: payload,
});

export const fetchClientListFromGoalError = (error) => ({
  type: action.GET_CLIENT_LIST_FROM_GOAL_ERROR,
  payload: error,
});

export const fetchCampaignFiltersData = () => ({
  type: action.GET_CAMPAIGNS_FILTERS_DATA,
  payload: {},
});

export const fetchCampaignFiltersSuccess = (payload) => ({
  type: action.GET_CAMPAIGNS_FILTERS_DATA_SUCCESS,
  payload: payload,
});

export const fetchCampaignFiltersError = (error) => ({
  type: action.GET_CAMPAIGNS_FILTERS_DATA_ERROR,
  payload: error,
});
