export const GET_CAMPAIGN_EVENTS = 'GET_CAMPAIGN_EVENTS';
export const GET_CAMPAIGN_EVENTS_SUCCESS = 'GET_CAMPAIGN_EVENTS_SUCCESS';
export const GET_CAMPAIGN_EVENTS_ERROR = 'GET_CAMPAIGN_EVENTS_ERROR';
export const GET_FILTERED_CAMPAIGN_EVENTS = 'GET_FILTERED_CAMPAIGN_EVENTS';

export const GET_CLIENT_LIST_FROM_GOAL = 'GET_CLIENT_LIST_FROM_GOAL';
export const GET_CLIENT_LIST_FROM_GOAL_SUCCESS = 'GET_CLIENT_LIST_FROM_GOAL_SUCCESS';
export const GET_CLIENT_LIST_FROM_GOAL_ERROR = 'GET_CLIENT_LIST_FROM_GOAL_ERROR';
export const GET_CAMPAIGNS_FILTERS_DATA = 'GET_CAMPAIGNS_FILTERS_DATA'
export const GET_CAMPAIGNS_FILTERS_DATA_SUCCESS = 'GET_CAMPAIGNS_FILTERS_DATA_SUCCESS'
export const GET_CAMPAIGNS_FILTERS_DATA_ERROR = 'GET_CAMPAIGNS_FILTERS_DATA_ERROR'
