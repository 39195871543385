const i18n_keys = {
  NO_EVENTS_TO_DISPLAY: 'NO_EVENTS_TO_DISPLAY',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED',
  STATE_REQUIRED: 'STATE_REQUIRED',
  PHONE_REQUIRED: 'PHONE_REQUIRED',
  COMPANY_NAME_REQUIRED: 'COMPANY_NAME_REQUIRED',
  ZIP_CODE_REQUIRED: 'ZIP_CODE_REQUIRED',
  ADDRESS_REQUIRED: 'ADDRESS_REQUIRED',
  CITY_REQUIRED: 'CITY_REQUIRED',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  CERTIFICATE_VAULT: 'CERTIFICATE_VAULT',
  NO_CERTIFICATES_TO_DISPLAY: 'NO_CERTIFICATES_TO_DISPLAY',
  NO_USERS_TO_DISPLAY: 'NO_USERS_TO_DISPLAY',
  REGISTRATION_SUCCESSFUL_MESSAGE: 'REGISTRATION_SUCCESSFUL_MESSAGE',
  DASHBOARD_ACTIVITY_BY_STATE: 'DASHBOARD_ACTIVITY_BY_STATE',
  SESSIONS: 'SESSIONS',
  AVG_DURATION: 'AVG_DURATION',
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  NO_CAMPAIGNS: 'NO_CAMPAIGNS',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  ENTER_NEW_PASSWORD: 'ENTER_NEW_PASSWORD',
  RE_ENTER_NEW_PASSWORD: 'RE_ENTER_NEW_PASSWORD',
  CREATE_NEW_PASSWORD_TO_PROCEED: 'CREATE_NEW_PASSWORD_TO_PROCEED',
  GET_FREE_CONSENT_CONFIRMATION_ACCOUNT: 'GET_FREE_CONSENT_CONFIRMATION_ACCOUNT',
  CREATE_CAMPAIGN_TOOLTIP_MESSAGE: 'CREATE_CAMPAIGN_TOOLTIP_MESSAGE',
  CAMPAIGN_NAME_TOOLTIP_MESSAGE: 'CAMPAIGN_NAME_TOOLTIP_MESSAGE',
  CERTIFICATE_TOOLTIP_MESSAGE: 'CERTIFICATE_TOOLTIP_MESSAGE',
  CAMPAIGN_DATE_TOOLTIP_MESSAGE: 'CAMPAIGN_DATE_TOOLTIP_MESSAGE',
  RETAIN_CERTIFICATE_FOR_TOOLTIP_MESSAGE: 'RETAIN_CERTIFICATE_FOR_TOOLTIP_MESSAGE',
  CERTIFICATE_ID_TOOLTIP_MESSAGE: 'CERTIFICATE_ID_TOOLTIP_MESSAGE',
  DATE_TOOLTIP_MESSAGE: 'DATE_TOOLTIP_MESSAGE',
  DURATION_TOOLTIP_MESSAGE: 'DURATION_TOOLTIP_MESSAGE',
  PAGE_URL_TOOLTIP_MESSAGE: 'PAGE_URL_TOOLTIP_MESSAGE',
  IP_ADDRESS_TOOLTIP_MESSAGE: 'IP_ADDRESS_TOOLTIP_MESSAGE',
  LOCATION_TOOLTIP_MESSAGE: 'LOCATION_TOOLTIP_MESSAGE',
  BROWSER_TOOLTIP_MESSAGE: 'BROWSER_TOOLTIP_MESSAGE',
  AVG_DURATION_TOOLTIP_MESSAGE: 'AVG_DURATION_TOOLTIP_MESSAGE',
  SESSIONS_TOOLTIP_MESSAGE: 'SESSIONS_TOOLTIP_MESSAGE',
  DEVICE_TYPE_TOOLTIP_MESSAGE: 'DEVICE_TYPE_TOOLTIP_MESSAGE',
  TOP_BROWSERS_TOOLTIP_MESSAGE: 'TOP_BROWSERS_TOOLTIP_MESSAGE',
  TOP_DOMAINS_TOOLTIP_MESSAGE: 'TOP_DOMAINS_TOOLTIP_MESSAGE',
  TOP_STATES_TOOLTIP_MESSAGE: 'TOP_STATES_TOOLTIP_MESSAGE',
  PAGE_TITLE_SUFFIX: 'PAGE_TITLE_SUFFIX',
  COPY_CERTIFICATE_URLTOOLTIP_MESSAGE: 'COPY_CERTIFICATE_URLTOOLTIP_MESSAGE',
  LAST_SIGN_IN_TOOLTIP_MESSAGE: 'LAST_SIGN_IN_TOOLTIP_MESSAGE',
  SIGN_IN_COUNT_TOOLTIP_MESSAGE: 'SIGN_IN_COUNT_TOOLTIP_MESSAGE',
  DASHBOARD_ACTIVITY_BY_STATE_TOOLTIP_MESSAGE: 'DASHBOARD_ACTIVITY_BY_STATE_TOOLTIP_MESSAGE',
  FORM_COMPLETED_TOOLTIP_MESSAGE: 'FORM_COMPLETED_TOOLTIP_MESSAGE',
  COMPLETED_CERTIFICATE_TOOTIP_MESSAGE: 'COMPLETED_CERTIFICATE_TOOTIP_MESSAGE',
  CONSENT_CAPTURED_TOOLTIP_MESSAGE: 'CONSENT_CAPTURED_TOOLTIP_MESSAGE',
  TCPA_DISCLAIMER_TOOLTIP_MESSAGE: 'TCPA_DISCLAIMER_TOOLTIP_MESSAGE'
}
export default i18n_keys;
