import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Label, Alert, FormFeedback } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../store/auth/login/actions";
import { Link, useNavigate } from 'react-router-dom';
import logoDark from "assets/images/logo-dark.png";
import i18n_keys from '../../i18n_keys';
import { useTranslation } from 'react-i18next';
import { errorMessages } from '../../common/utils/data_validations';
import { useFormik } from "formik";
import * as Yup from "yup"
import * as CONSTANTS from "../../common/utils/data_validations";
import useClearableTimeout from "hooks/useClearableTimeout";
import { AppConfiguration } from "config";

const Login = () => {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regSuccess, setRegSuccess] = useState(null);
  const [sessionExp, setSessionExp] = useState(null);
  const urlParams = new URLSearchParams(location.search);
  const { error } = useSelector(state => ({
    error: state.authReducer.error,
  }));
  const isLoggedIn = !!localStorage.getItem('authToken');
  const { t } = useTranslation();
  const session_expired = urlParams.get('session_expired')
  const registration_successful = urlParams.get('registration')

  const [flashMessageTimeout] = useClearableTimeout();
  const [flashMessageState, setFlashMessageState] = useState(null);

  const [get_flash_message, set_flash_message] = useState({
    type: urlParams.get("flash_message_type"),
    text: urlParams.get("flash_message_text")
  })

  useEffect(() => {
    if (!error) {
      return;
    }
    set_flash_message({ type: "danger", text: error })
  }, [error])

  const handleFlashMessage = () => {
    setFlashMessageState(get_flash_message);
    flashMessageTimeout(() => {
      setFlashMessageState(null)
    }, 5000)
  }

  useEffect(() => {
    handleFlashMessage();
  }, [get_flash_message])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      remember_me: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email()
        .required(t(i18n_keys.EMAIL_REQUIRED))
        .matches(
          CONSTANTS.emailRegex,
          CONSTANTS.STR_INVALID_EMAIL),
      password: Yup.string().required(t(i18n_keys.PASSWORD_REQUIRED)),
      otp: Yup.string(),
    }),

    onSubmit: (values) => {
      dispatch(loginRequest(values.email, values.password, navigate));
    },
  });

  useEffect(() => {
    if (session_expired) {
      setSessionExp(errorMessages.AUTHENTICATION_TOKEN_HAS_EXPIRED);
    }
    if (registration_successful == "success") {
      setRegSuccess(t(i18n_keys.REGISTRATION_SUCCESSFUL_MESSAGE));
    }
  }, [session_expired, registration_successful])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-white">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="text-center p-4">
                        <img src={logoDark} height="70" alt="Logo" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  {flashMessageState && flashMessageState?.text ? (
                    <Alert color={flashMessageState?.type}>
                      {flashMessageState?.text}
                    </Alert>
                  ) : null}
                  {regSuccess ? (
                    <Alert color="success">
                      {regSuccess}
                    </Alert>
                  ) : null}
                  {sessionExp ? (
                    <Alert color="danger">
                      {sessionExp}
                    </Alert>
                  ) : null}
                  <Form className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <div className="mb-3">
                      <Label className="form-label font-weight-semibold" htmlFor="email">Email</Label>
                      <Input
                        name="email"
                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={
                          !!(validation.touched.email && validation.errors.email)
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label font-weight-semibold" htmlFor="password">Password</Label>
                      <Input
                        name="password"
                        className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                        type="password"
                        placeholder="Enter Password"
                        value={validation.values.password || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          !!(validation.touched.password
                            && validation.errors.password)
                        }
                      />
                      {validation.touched.password
                        && validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        name="remember_me"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </Label>
                    </div>
                    <div className="mt-3 d-grid">
                      <button className="btn btn-primary btn-block" type="submit">Log In</button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="fw-medium text-blue">
                        Forgot your password?
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p className='text-white'>
                  Don't have an account ?
                  <a href="/register" className="fw-medium text-blue"> Signup now</a>
                </p>
                <p className='text-white'>
                  ©  {new Date().getFullYear()} {AppConfiguration.REACT_APP_PRODUCT_NAME}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Login;
