import { AppConfiguration } from "../config";

const fetchData = async ({ eventId, apiToken }) => {
  try {
    let user = localStorage.getItem("authToken")
    let apiUrl = apiToken ? `${AppConfiguration.BACKEND_URL}/event/${eventId}/${apiToken}` : `${AppConfiguration.BACKEND_URL}/event/${eventId}`
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const login = async (email, password) => {
  try {
    const response = await fetch(`${AppConfiguration.BACKEND_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const registerUser = async (first_name,
  last_name,
  phone,
  company_name,
  address,
  city,
  state,
  zip_code,
  email,
  password) => {
  try {
    const response = await fetch(`${AppConfiguration.BACKEND_URL}/createUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name,
        last_name,
        phone,
        company_name,
        address,
        city,
        state,
        zip_code,
        email,
        password
      }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
}

const getCampaigns = async () => {
  try {
    let user = localStorage.getItem("authToken")
    const response = await fetch(`${AppConfiguration.BACKEND_URL}/campaigns`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      }
    });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

/**
 * Returns a query string for the parameters passes
 *
 * @param {Object}  query_params object with key = param, value = param_value
 */
const getQueryParamsFromObject = (query_params, skip_undefined = false) => {
  if (!query_params) {
    return ""
  }

  const query_string_arr = []
  for (const param in query_params) {
    if (skip_undefined && (query_params[param] === null || query_params[param] === undefined)) {
      continue;
    }
    const query_string = `${encodeURIComponent(param)}=${encodeURIComponent(
      query_params[param]
    )}`
    query_string_arr.push(query_string)
  }
  return query_string_arr.join("&")
}

const getCampaignsStats = async (payload) => {
  try {
    if (!payload || !payload.queryParams) {
      return;
    }
    const queryString = getQueryParamsFromObject(payload?.queryParams)
    let user = localStorage.getItem("authToken");
    let response = await fetch(`${AppConfiguration.BACKEND_URL}/campaignStats?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
    });
    response = await response.json();
    return response.data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

const getCampaignEvents = async (payload) => {
  try {
    const queryString = getQueryParamsFromObject(payload?.queryParams)
    let user = localStorage.getItem("authToken");
    let response = await fetch(`${AppConfiguration.BACKEND_URL}/campaignEvents?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
    });
    response = await response.json();
    return response.data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

const getFilteredCampaignEvents = async (payload) => {
  try {
    let user = localStorage.getItem("authToken");
    let response = await fetch(`${AppConfiguration.BACKEND_URL}/campaign_events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
      body: JSON.stringify(payload),
    });
    response = await response.json();
    return response.data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

const getCampaignEventStats = async (payload) => {
  try {
    const queryString = getQueryParamsFromObject(payload?.queryParams)
    let user = localStorage.getItem("authToken");
    let response = await fetch(`${AppConfiguration.BACKEND_URL}/campaignEventStats?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
    });
    response = await response.json();
    return response.data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

const createCampaign = async (campaignData) => {
  let user = localStorage.getItem("authToken")
  try {
    const response = await fetch(`${AppConfiguration.BACKEND_URL}/campaigns`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user,
      },
      body: JSON.stringify(campaignData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

const getCampaignById = async (campaignId) => {
  try {
    let userToken = localStorage.getItem("authToken");

    const response = await fetch(`${AppConfiguration.BACKEND_URL}/campaign/${campaignId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error getting campaign by ID:', error);
    throw error;
  }
};

const updateCampaign = async (campaignData) => {
  try {
    let userToken = localStorage.getItem("authToken");

    const response = await fetch(`${AppConfiguration.BACKEND_URL}/campaign/${campaignData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
      body: JSON.stringify(campaignData),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error updating campaign:', error);
    throw error;
  }
};

const currentUser = async () => {
  try {
    let userToken = localStorage.getItem("authToken");

    const response = await fetch(`${AppConfiguration?.BACKEND_URL}/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating campaign:', error);
    throw error;
  }
};

const consentDetails = async (certificateID) => {
  try {
    let userToken = localStorage.getItem("authToken");

    const response = await fetch(`${AppConfiguration.BACKEND_URL}/getSessionDetails/${certificateID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating campaign:', error);
    throw error;
  }
};

export { fetchData, login, registerUser, getCampaigns, createCampaign, getCampaignsStats, getCampaignById, updateCampaign, currentUser, getCampaignEvents, getCampaignEventStats, consentDetails, getFilteredCampaignEvents };
