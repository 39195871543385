import { call, put, takeLatest } from 'redux-saga/effects';
import { getCampaignEvents } from '../../utils/Api';
import * as action_type from './actionTypes';
import { AppConfiguration } from 'config';
import {
  fetchCampaignEventsError,
  fetchCampaignEventsSuccess,
  fetchCampaignFiltersError,
  fetchCampaignFiltersSuccess,
  fetchClientListFromGoalError,
  fetchClientListFromGoalSuccess,
} from './actions';
import { genericGetRequest, genericPostRequest } from 'helpers/backend_helper';

export function* onGetCampaignEvents(action) {
  try {
    const statsData = yield call(getCampaignEvents, action.payload);
    yield put(fetchCampaignEventsSuccess(statsData));
  } catch (error) {
    yield put(fetchCampaignEventsError(error));
  }
}

export function* onGetFilteredCampaignEvents(action) {
  try {
    const URL = `${AppConfiguration.BACKEND_URL}/campaign_events`;
    const statsData = yield call(genericPostRequest, URL, action.payload, {}, true);
    yield put(fetchCampaignEventsSuccess(statsData));
  } catch (error) {
    yield put(fetchCampaignEventsError(error));
  }
}

export function* onGetClientsFromGoal() {
  try {
    const URL = `${AppConfiguration.GOAL_BACKEND_URL}/api/v1/account/get_account_details`
    const statsData = yield call(genericGetRequest, URL);
    yield put(fetchClientListFromGoalSuccess(statsData));
  } catch (error) {
    yield put(fetchClientListFromGoalError(error));
  }
}

export function* getCampaignFilters() {
  try {
    const URL = `${AppConfiguration.BACKEND_URL}/campaign_events/filters`
    const statsData = yield call(genericGetRequest, URL);
    yield put(fetchCampaignFiltersSuccess(statsData));
  } catch (error) {
    yield put(fetchCampaignFiltersError(error));
  }
}


export default function* CertificateVaultSaga() {
  yield takeLatest(action_type.GET_CAMPAIGN_EVENTS, onGetCampaignEvents);
  yield takeLatest(action_type.GET_FILTERED_CAMPAIGN_EVENTS, onGetFilteredCampaignEvents);
  yield takeLatest(action_type.GET_CLIENT_LIST_FROM_GOAL, onGetClientsFromGoal);
  yield takeLatest(action_type.GET_CAMPAIGNS_FILTERS_DATA, getCampaignFilters);
}