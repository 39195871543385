import PropTypes from "prop-types"
import { React } from "react"
import { Modal, Form } from "reactstrap"

const TcpaDisclaimerModal = ({ state, onCloseStateEvent, disclaimerText }) => {

  return (
    <Modal size="md" isOpen={state.action} toggle={onCloseStateEvent} centered={true} >
      <div className="modal-header">
        <h5 className="modal-title text-black font-weight-semibold mt-0" id="myModalLabel">
          TCPA Disclaimer
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCloseStateEvent}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form>
        <div className="modal-body my-3">
          <p className="text-break">{disclaimerText || ""}</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={onCloseStateEvent}
            className="btn btn-light "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Form>
    </Modal>
  )
}

TcpaDisclaimerModal.propTypes = {
  onCloseStateEvent: PropTypes.func,
  state: PropTypes.object,
  disclaimerText: PropTypes.object,
}

export default TcpaDisclaimerModal
