// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchDashboardBrowserTypeStatsError,
  fetchDashboardBrowserTypeStatsSuccess,
  fetchDashboardCompareToMainStatsError,
  fetchDashboardCompareToMainStatsSuccess,
  fetchDashboardDeviceTypeStatsError,
  fetchDashboardDeviceTypeStatsSuccess,
  fetchDashboardDomainStatsError,
  fetchDashboardDomainStatsSuccess,
  fetchDashboardMainDetailStatsError,
  fetchDashboardMainDetailStatsSuccess,
  fetchDashboardMainStatsError,
  fetchDashboardMainStatsSuccess,
  fetchDashboardStateStatsError,
  fetchDashboardStateStatsSuccess,
} from './actions';
import { genericPostRequest } from 'helpers/backend_helper';
import { AppConfiguration } from 'config';
import { POST_DASHBOARD_BROWSER_TYPE_STATS, POST_DASHBOARD_COMPARE_TO_MAIN_STATS, POST_DASHBOARD_DEVICE_TYPE_STATS, POST_DASHBOARD_DOMAIN_STATS, POST_DASHBOARD_MAIN_DETAIL_STATS, POST_DASHBOARD_MAIN_STATS, POST_DASHBOARD_STATE_STATS } from './actionTypes';

export function* onPostDashboardMainStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/summary`:`${AppConfiguration.BACKEND_URL}/dashboard/main_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardMainStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardMainStatsError(error));
  }
}

export function* onPostDashboardCompareToMainStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/compare_to_summary` : `${AppConfiguration.BACKEND_URL}/dashboard/compare_to_main_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardCompareToMainStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardCompareToMainStatsError(error));
  }
}

export function* onPostDashboardMainDetailStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/graph_data` : `${AppConfiguration.BACKEND_URL}/dashboard/graph_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardMainDetailStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardMainDetailStatsError(error));
  }
}

export function* onPostDashboardDeviceTypeStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/device_type_summary` : `${AppConfiguration.BACKEND_URL}/dashboard/device_type_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardDeviceTypeStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardDeviceTypeStatsError(error));
  }
}

export function* onPostDashboardBrowserTypeStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/browser_type_summary` : `${AppConfiguration.BACKEND_URL}/dashboard/browser_type_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardBrowserTypeStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardBrowserTypeStatsError(error));
  }
}

export function* onPostDashboardDomainStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/top_domain_summary` : `${AppConfiguration.BACKEND_URL}/dashboard/domain_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardDomainStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardDomainStatsError(error));
  }
}

export function* onPostDashboardStateStats(action) {
  try {
    const URL = AppConfiguration.USE_RAILS_API ? `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/dashboard/top_states_summary` : `${AppConfiguration.BACKEND_URL}/dashboard/state_data`;
    const res = yield call(genericPostRequest, URL, action.payload);
    yield put(fetchDashboardStateStatsSuccess(res));
  } catch (error) {
    yield put(fetchDashboardStateStatsError(error));
  }
}
0
export default function* DashboardSaga() {
  yield takeLatest(POST_DASHBOARD_MAIN_STATS, onPostDashboardMainStats);
  yield takeLatest(POST_DASHBOARD_COMPARE_TO_MAIN_STATS, onPostDashboardCompareToMainStats);
  yield takeLatest(POST_DASHBOARD_MAIN_DETAIL_STATS, onPostDashboardMainDetailStats);
  yield takeLatest(POST_DASHBOARD_DEVICE_TYPE_STATS, onPostDashboardDeviceTypeStats);
  yield takeLatest(POST_DASHBOARD_BROWSER_TYPE_STATS, onPostDashboardBrowserTypeStats);
  yield takeLatest(POST_DASHBOARD_DOMAIN_STATS, onPostDashboardDomainStats);
  yield takeLatest(POST_DASHBOARD_STATE_STATS, onPostDashboardStateStats);
}
