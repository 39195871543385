import { takeLatest, call, put } from 'redux-saga/effects';
import { loginSuccess, loginFailure } from './actions';
import { login } from '../../../utils/Api';
import { LOGIN_REQUEST } from './actionTypes';
import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';

export function* handleLogin(action) {
  const { email, password, navigate } = action.payload;
  const redirectTo = localStorage.getItem(STORAGE_KEY.REDIRECT_PATH) || "/dashboard";

  try {
    const userData = yield call(login, email, password);
    localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, (userData.access_token));
    yield put(loginSuccess(userData));

    navigate(redirectTo)
    localStorage.removeItem(STORAGE_KEY.REDIRECT_PATH);

  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}