export const bsh_getUserAuthToken = () => {
  try {
    const userAuthToken = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
    return userAuthToken;
  } catch (error) {
    console.error('Error getting user token:', error);
    return null;
  }
}

export const bsh_getFilters = (filters, field) => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY.FILTERS.NAME))?.[filters]?.[field];
}

export const STORAGE_KEY = {
  AUTH_USER: 'authUser',
  AUTH_TOKEN: 'authToken',
  FILTERS: {
    NAME: 'filters',
    CAMPAIGN: 'campaign',
  },
  REDIRECT_PATH: 'redirectPath'
}

export const bsh_setFilters = (filters, field, value) => {
  let filtersObj = JSON.parse(localStorage.getItem(STORAGE_KEY.FILTERS.NAME));
  if (!filtersObj) {
    let data = {
      [filters]: {
        [field]: [value]
      }
    }
    localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(data));
    return;
  } else {
    if (filtersObj?.[filters]) {
      filtersObj[filters][field] = value;
      localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(filtersObj));
    } else {
      filtersObj[filters] = {
        [field]: value
      }
      localStorage.setItem(STORAGE_KEY.FILTERS.NAME, JSON.stringify(filtersObj));
    }
  }
}

