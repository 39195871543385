import _ from 'lodash';
import * as action_type from './actionTypes';

const initialState = {
  campaignEvents: [],
  loading: false,
  error: null,
  last_page_fetched: 0,
  has_next_page: false,
  clientList: [],
  client_list_loading: false,
  filter_data: [],
  loading_filters: false
};

const CertificateVault = (state = initialState, action) => {
  switch (action.type) {
    case action_type.GET_CAMPAIGN_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case action_type.GET_CAMPAIGN_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        last_page_fetched: parseInt(action.payload.additional_data?.page_number),
        has_next_page: action.payload.additional_data?.has_next_page,
        campaignEvents: _.uniqBy([...state.campaignEvents, ...action?.payload?.data], 'certificate_id'),
      };
    case action_type.GET_CAMPAIGN_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case action_type.GET_FILTERED_CAMPAIGN_EVENTS:
      const page_number = parseInt(action.payload.page_number || 1)
      return {
        ...state,
        loading: true,
        error: null,
        campaignEvents: (page_number === 1) ? [] : state.campaignEvents,
      };
    case action_type.GET_CLIENT_LIST_FROM_GOAL:
      return {
        ...state,
        client_list_loading: true,
        error: null,
      };
    case action_type.GET_CLIENT_LIST_FROM_GOAL_SUCCESS:
      return {
        ...state,
        client_list_loading: false,
        clientList: action.payload,
      };
    case action_type.GET_CLIENT_LIST_FROM_GOAL_ERROR:
      return {
        ...state,
        client_list_loading: false,
        error: action.payload,
      };
    case action_type.GET_CAMPAIGNS_FILTERS_DATA:
      return {
        ...state,
        loading_filters: true
      };
    case action_type.GET_CAMPAIGNS_FILTERS_DATA_SUCCESS:
      return {
        ...state,
        loading_filters: false,
        filter_data: action.payload,
      };
    case action_type.GET_CAMPAIGNS_FILTERS_DATA_ERROR:
      return {
        ...state,
        client_list_loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default CertificateVault;